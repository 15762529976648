/** @jsx jsx */
import { Box, jsx } from 'theme-ui';
import PropTypes from 'prop-types';

const Heading = ({ children, isOpen, ...props }) => (
  <Box
    {...props}
    as="h2"
    sx={{
      fontFamily: 'body',
      fontSize: [0, 1, null, 2],
      fontStyle: 'italic',
      listStyle: 'none',
      mb: [null, null, null, 2],
      textAlign: ['right', null, null, 'left'],
      textTransform: 'uppercase',
      cursor: 'pointer',
      ':after': {
        content: isOpen ? `" –"` : `" +"`,
      },
    }}
  >
    {children}
  </Box>
);

Heading.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
};

const Links = ({ children, isOpen, ...props }) => {
  return (
    <Box
      as="ul"
      {...props}
      sx={{
        fontStyle: 'italic',
        listStyle: 'none',
        maxHeight: isOpen ? '100vh' : 0,
        mt: isOpen ? 3 : 0,
        textAlign: ['right', null, null, 'left'],
        textTransform: 'uppercase',
        opacity: isOpen ? '100%' : 0,
        overflow: !isOpen && 'hidden',
        transition: '0.5s ease-in-out all',
      }}
    >
      {children}
    </Box>
  );
};

Links.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
};

const Link = ({ children, isActive, ...props }) => (
  <li
    {...props}
    sx={{
      variant: 'links',
      color: isActive ? 'highlight' : 'text',
      fontSize: [0, 1, null],
      fontWeight: isActive && 'bold',
      letterSpacing: `0.025rem`,
      lineHeight: 'list',
      ':after': isActive && {
        content: '" ⅹ"',
        fontSize: [0, 1, null],
      },
    }}
  >
    {children}
  </li>
);

Link.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool,
};

export { Heading, Links, Link };
