/** @jsx jsx */
import { Box, Flex, jsx } from 'theme-ui';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import { HorizontalScroll } from '../horizontal-scroll';
import { HEADER_HEIGHT } from '../header';

const Wrapper = ({ children, ...props }) => (
  <HorizontalScroll
    sx={{
      '@media (orientation: landscape)': {
        mt: HEADER_HEIGHT.lg,
        px: [0, null, null, 3],
        '> *': {
          height: '100%',
          mx: 3,
        },
      },
    }}
    {...props}
  >
    {children}
  </HorizontalScroll>
);

Wrapper.propTypes = {
  children: PropTypes.node,
};

const ProjectLink = ({ children, disabled, ...props }) =>
  disabled ? (
    <Box
      {...props}
      sx={{
        flexShrink: 0,
        mx: [0, null, null, 3], // Override margin:0 on ProjectLink. Not sure why it's there.
        position: 'relative',
      }}
    >
      {children}
    </Box>
  ) : (
    <Link
      {...props}
      sx={{
        position: 'relative',
        textDecoration: 'none',
        ':hover *': {
          transition: '0.2s ease-in',
          color: 'primary',
        },
      }}
    >
      {children}
    </Link>
  );

ProjectLink.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

const ProjectTitle = ({ height, hasEnabledLink, title, ...props }) => (
  <Box
    sx={{
      flexDirection: ['row', null, 'column'],
      color: ['text', null, 'white'],
      height,
      lineHeight: 'heading',
      mt: [1, null, null, 3],
      mb: [4, null, null, 3],
      // @TODO: For some reason the value is 0 at 960px.
      ml: [2, null, null, 0],
      textAlign: 'center',
      transition: '0.5s ease',
    }}
    {...props}
  >
    <Flex as="h2" sx={{ fontSize: [1, 2, 3] }}>
      {title}
      {hasEnabledLink && (
        <Box sx={{ ml: 1 }}>
          <svg
            width="12"
            height="12"
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M14 5l7 7m0 0l-7 7m7-7H3"
            ></path>
          </svg>
        </Box>
      )}
    </Flex>
  </Box>
);

ProjectTitle.propTypes = {
  height: PropTypes.string,
  title: PropTypes.string,
  hasEnabledLink: PropTypes.bool,
};

const Placeholder = () => (
  <Box
    sx={{
      backgroundColor: 'primary',
      opacity: 1,
      width: '100%',
      height: '100%',
    }}
  />
);

export { ProjectLink, Placeholder, ProjectTitle, Wrapper };
