/** @jsx jsx */
import {
  groupBy,
  isEmpty,
  sortBy,
  map,
  toNumber,
  size,
  includes,
} from 'lodash';
import { graphql, useStaticQuery } from 'gatsby';
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';
import { Fragment, useEffect } from 'react';

import { Heading, Link, Links } from './components';
import { useFilters } from '../../context/filters-context';

export const Filters = ({ setQuery, ...props }) => {
  const data = useStaticQuery(
    graphql`
      {
        allWpTag {
          nodes {
            id
            name
            count
            description
            slug
          }
        }
      }
    `
  );

  const { state, dispatch } = useFilters();

  useEffect(() => {
    setQuery({
      filters: state.filters,
    });
  }, [state.filters]);

  const activeFilters = state.filters;
  const onToggleFilter = evt => {
    evt.preventDefault();
    const filter = evt.target.dataset.name;
    if (filter === 'all') {
      dispatch({ type: 'TOGGLE_ALL_FILTERS' });
    } else {
      const isFilterActive = includes(activeFilters, filter);
      isFilterActive
        ? dispatch({ type: 'REMOVE_FILTER', value: filter })
        : dispatch({ type: 'ADD_FILTER', value: filter });
    }
  };

  return (
    <PureFilters
      activeFilters={activeFilters}
      onToggleFilter={onToggleFilter}
      data={data}
      {...props}
    />
  );
};

Filters.propTypes = {
  setQuery: PropTypes.func.isRequired,
};

export const PureFilters = ({
  activeFilters,
  data,
  isMenuOpen,
  onToggleFilter,
  setIsMenuOpen,
}) => {
  // Split the tags into two groups: one with description and one without.
  const groupedFilters = groupBy(
    data.allWpTag.nodes,
    ({ description }) => !isEmpty(description)
  );

  // Sort the tags with description by description.
  const sortedFilters = sortBy(
    map(groupedFilters.true, node => ({
      ...node,
      description: toNumber(node.description),
    })),
    'description'
  );

  return (
    <Fragment>
      <Heading isOpen={isMenuOpen} onClick={() => setIsMenuOpen(!isMenuOpen)}>
        Filters
      </Heading>
      <Links isOpen={isMenuOpen}>
        <Link
          data-name="all"
          onClick={evt => onToggleFilter(evt)}
          isActive={size(activeFilters) === 0}
        >
          All
        </Link>
        {map(sortedFilters, ({ id, slug, name }) => (
          <Link
            key={id}
            data-name={slug}
            onClick={evt => onToggleFilter(evt)}
            isActive={includes(activeFilters, name)}
          >
            {name}
          </Link>
        ))}
        {map(groupedFilters.false, ({ id, slug, name }) => (
          <Link
            key={id}
            data-name={slug}
            onClick={evt => onToggleFilter(evt)}
            isActive={includes(activeFilters, name)}
          >
            {name}
          </Link>
        ))}
      </Links>
    </Fragment>
  );
};

PureFilters.propTypes = {
  activeFilters: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.shape({
    allWpTag: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
  isMenuOpen: PropTypes.bool,
  onToggleFilter: PropTypes.func.isRequired,
  setIsMenuOpen: PropTypes.func.isRequired,
};

PureFilters.defaultProps = {
  activeFilters: [],
  isMenuOpen: false,
};

export default Filters;
