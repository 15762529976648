/** @jsx jsx */
import { map, isEmpty, reject, intersection } from 'lodash';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import { useQueryParams, ArrayParam } from 'use-query-params';
import PropTypes from 'prop-types';
import { Fragment } from 'react';

import FeaturedImages from '../components/featured-images';
import Filters from '../components/filters';
import Menu from '../components/menu';
import SEO from '../components/seo';
import { useMenu } from '../context/menu-context';

export const IndexPageQuery = graphql`
  {
    allWpPost(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          id
          date
          title
          projectInfo {
            disableProjectPage
          }
          featuredImage {
            node {
              id
              sourceUrl
              altText
              link
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 80
                    placeholder: NONE
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          tags {
            nodes {
              id
              slug
            }
          }
          uri
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  const posts = map(data.allWpPost.edges, ({ node }) => node);
  const [query, setQuery] = useQueryParams({ filters: ArrayParam });
  const filterByQueryParams = (posts, { filters }) => {
    if (!isEmpty(filters)) {
      return reject(posts, ({ tags }) =>
        isEmpty(
          intersection(
            map(tags.nodes, ({ slug }) => slug),
            filters
          )
        )
      );
    }

    return posts;
  };

  const { isMenuOpen, setIsMenuOpen } = useMenu();

  return (
    <Fragment>
      <SEO title="Home" />
      <FeaturedImages
        posts={filterByQueryParams(posts, query)}
        template="project"
      />
      <Menu width={null}>
        <Filters {...{ isMenuOpen, setIsMenuOpen, setQuery }} />
      </Menu>
    </Fragment>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default IndexPage;
