/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';

import { getImage } from 'gatsby-plugin-image';
import { ProjectLink, ProjectTitle, Wrapper } from './components';
import FullHeightGatsbyImage from '../full-height-image';

const FeaturedImages = ({ posts, template }) => {
  return (
    <Wrapper id="featured-images">
      {map(posts, ({ id, title, featuredImage, projectInfo, uri }) => {
        const image = featuredImage && getImage(featuredImage?.node?.localFile);

        return (
          featuredImage && (
            <ProjectLink
              key={id}
              to={`/${template}${uri}`}
              disabled={projectInfo.disableProjectPage}
            >
              {featuredImage?.node?.localFile && (
                <Fragment>
                  <FullHeightGatsbyImage image={image} />
                  <ProjectTitle
                    hasEnabledLink={!projectInfo.disableProjectPage}
                    {...{ title }}
                  />
                </Fragment>
              )}
            </ProjectLink>
          )
        );
      })}
    </Wrapper>
  );
};

FeaturedImages.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      featuredImage: PropTypes.shape({
        alt_text: PropTypes.string,
        id: PropTypes.string,
        path: PropTypes.string,
        tags: PropTypes.array,
        title: PropTypes.string,
      }),
      uri: PropTypes.string,
    })
  ).isRequired,
  template: PropTypes.string,
};

FeaturedImages.defaultProps = {
  template: '',
};

export default FeaturedImages;
